import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/class/list",
        component: () => import("@/views/class/list"),
    },
    // 声动课堂列表
    {
        path: "/class/list",
        name: "class",
        component: () => import("@/views/class/list"),
    },
    {
        path: "/class/detail",
        name: "classDetail",
        component: () => import("@/views/class/detail"),
    }
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;