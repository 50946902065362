import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'

Vue.use(ElementUI);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if(to.path == '/class/detail'){
    // let title = null;
    // if(localStorage.getItem('detail')){
    //   title = JSON.parse(localStorage.getItem('detail')).title
    // }
    // document.title = title ? title : '声动课堂';
  }else{
    document.title = '声动课堂';
  }
  next();
  
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
